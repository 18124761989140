import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import {
  withPrismicUnpublishedPreview,
} from 'gatsby-plugin-prismic-previews'

import LayoutNew from "../components/layout-new"
import Seo from "../components/seo"

const NotFoundPage = () => (  
  <LayoutNew>
    <Seo title="404: Page Not found" noIndex />

    <div className="w-full h-screen flex flex-col items-center justify-center px-6">
      <div className="flex flex-col items-center justify-center">
        <StaticImage
          src="../../src/images/logos/SRA-831b-logo-dark.png"
          width={236}
          quality={95}
          formats={["AUTO", "WEBP"]}
          alt="SRA 831(b) ADMIN logo"
        />
        <h1 className="text-6xl font-extrabold text-site-red text-center mb-6">404: Page Not Found</h1>
        <p className="text-lg font-semibold text-center">You just hit a route that doesn&#39;t exist.</p>
        <Link to="/">
          <p className="text-center mt-4 uppercase underline">Go to Home Page</p>
        </Link>
      </div>
    </div>
  </LayoutNew>
)

export default withPrismicUnpublishedPreview(NotFoundPage)
